<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        x-large
        v-on="on"
        @click="alert"
      >
        <v-icon>mdi-minus-circle-outline</v-icon>
      </v-btn>
    </template>
    <span>{{ $t('Remove cover photo') }}</span>
  </v-tooltip>
</template>
<script>
export default {
  data () {
    return {
      rtl: this.$vuetify.rtl
    }
  },
  watch: {
    '$vuetify.rtl' () {
      this.$vuetify.rtl = this.rtl
    }
  },
  methods: {
    alert () {
      this.$emit('click')
      const message = this.$t('Are you sure you want to remove the cover photo?')
      this.$confirm(message, { title: this.$t('Remove'), buttonTrueText: this.$t('Remove'), buttonFalseText: this.$t('Cancel'), color: 'primary', icon: '' })
        .then(res => {
          if (res) {
            this.$emit('remove')
          }
          this.$emit('close')
        })
    }
  }
}
</script>
