<template>
  <select-cover-photo
    v-bind="$props"
    :open="open"
    @close="close"
    @set="$emit('set', $event)"
  >
    <template v-slot:button>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            x-large
            v-on="on"
            @click="click"
          >
            <v-icon>mdi-circle-edit-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('Edit cover photo') }}</span>
      </v-tooltip>
    </template>
  </select-cover-photo>
</template>

<script>
import SelectCoverPhoto from '@/components/common/photos/cover/Select.vue'
export default {
  components: {
    SelectCoverPhoto
  },
  props: {
    prefix: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      open: false
    }
  },
  methods: {
    close () {
      this.open = false
      this.$emit('close')
    },
    click () {
      this.open = true
      this.$emit('click')
    }
  }
}
</script>
