<template>
  <v-card
    flat
    height="500px"
  >
    <multiple-marker-map
      :center="center"
      :places="places"
      @click:place="emit($event)"
    />
  </v-card>
</template>

<script>
import MultipleMarkerMap from '@/components/common/MultipleMarkerMap.vue'
export default {
  components: { MultipleMarkerMap },
  props: {
    parent: {
      type: Object,
      required: true
    },
    children: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      updatedKey: 0
    }
  },
  computed: {
    center () {
      return this.stringToObject(this.parent.address).coordinates
    },
    places () {
      const childrenWithAddress = this.children.filter(el => this.getAttributeValue(el.entity, 'address'))
      const places = childrenWithAddress.map(el => {
        const address = this.stringToObject(this.getAttributeValue(el.entity, 'address'))
        return {
          id: el.entity.id,
          description: el.entity.name,
          coordinates: address.coordinates,
          payload: el
        }
      })
      return places
    }
  },
  methods: {
    emit (event) {
      this.$emit('click:child', event)
    },
    getAttributeValue (entity, name) {
      const attr = entity.attributes.find(attr => attr.name === name)
      return attr ? attr.value : null
    },
    stringToObject (string) {
      const splits = string.split(';')
      const label = splits[0].split(':')[1]
      const coordsString = splits[1].split(':')[1]
      const coords = coordsString.split(',')
      const coordinates = [Number(coords[0]), Number(coords[1])]
      const address = { label, coordinates }
      return address
    }
  }
}
</script>
