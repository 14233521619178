<template>
  <div>
    <v-progress-linear
      v-if="loading"
      :active="loading"
      :indeterminate="loading"
    />
    <div
      id="map1"
    />
  </div>
</template>
<script>
import { credentials, mapName, transformRequest } from '@/app/location.js'
import Mapbox from 'mapbox-gl'

export default {
  props: {
    long: {
      type: Number,
      default: 0
    },
    lat: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      accessToken: 'pk.eyJ1IjoiZ2lsaWEiLCJhIjoiY2tvbDdxcXc5MGN2dTJwcGV4MDdieGNheSJ9._prw-hU90KiNqOKyWiLjRw',
      loading: false
    }
  },
  async created () {
    await this.createMap()
  },
  methods: {
    async createMap () {
      // load credentials and set them up to refresh
      this.loading = true
      await credentials.getPromise()
      // actually initialize the map
      const center = [this.long, this.lat]
      const options = {
        accessToken: this.accessToken,
        container: 'map1',
        center,
        zoom: 15,
        style: mapName,
        transformRequest
      }
      const map = new Mapbox.Map(options)
      map.addControl(new Mapbox.NavigationControl(), 'top-left')
      new Mapbox
        .Marker({ color: 'red' })
        .setLngLat(center)
        .addTo(map)
      map.on('load', () => {
        this.loading = false
      })
    }
  }
}
</script>
<style scoped>
#map1 {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100% !important;
}
</style>
