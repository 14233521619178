<template>
  <v-menu
    v-model="menu"
    offset-y
    bottom
    nudge-bottom="10"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-hover>
        <v-btn
          icon
          small
          :disabled="disabled"
          color="primary"
          v-on="on"
        >
          <v-icon>
            mdi-map-marker-outline
          </v-icon>
        </v-btn>
      </v-hover>
    </template>

    <v-card
      width="400px"
      height="300px"
    >
      <v-map
        :key="updatedKey"
        :long="coordinates[0]"
        :lat="coordinates[1]"
      />
    </v-card>
  </v-menu>
</template>
<script>
import Map from '@/components/common/SingleMarkerMap.vue'
export default {
  components: {
    'v-map': Map
  },
  props: {
    coordinates: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      menu: false,
      updatedKey: 0
    }
  },
  computed: {
    disabled () {
      return this.coordinates[0] === 0 && this.coordinates[1] === 0
    }
  },
  watch: {
    coordinates (value, oldValue) {
      if (this.coordinates[0] !== 0 && this.coordinates[1] !== 0 && this.isNewCoordinates(value, oldValue)) {
        this.updatedKey = this.updatedKey + 1
      }
    }
  },
  methods: {
    isNewCoordinates (newCoords, oldCoords) {
      return newCoords[0] !== oldCoords[0] || newCoords[1] !== oldCoords[1]
    }
  }
}
</script>
