<template>
  <v-card
    v-if="tenant"
    max-width="1200"
    flat
  >
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col>
          <cover-photo
            v-if="coverPhoto"
            :prefix="id"
            :photo-key="coverPhoto"
            @set="setCoverPhoto"
          />
          <v-card-title class="pb-0">
            {{ title }}
            <v-spacer />
            <v-card-actions>
              <v-spacer />
              <cover-photo-button
                v-if="!coverPhoto"
                :prefix="id"
                @set="setCoverPhoto"
              />
              <SiteButtonDelete
                v-if="canDeleteTenant"
                class="ml-3 mr-3"
                :entity-id="id"
                :tenant-id="tenantId"
                :name="title"
                :is-tenant="true"
                @delete="$emit('delete')"
              />
              <v-btn
                v-if="canEditTenant"
                color="success"
                @click="saveChanges"
              >
                <v-icon left>
                  mdi-check-circle-outline
                </v-icon>
                {{ $t('Save Changes') }}
              </v-btn>
            </v-card-actions>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="description"
                    prepend-icon="mdi-comment-text-outline"
                    :readonly="!canEditTenant"
                    :label="$t('Description')"
                    :rules="descriptionRules"
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="erpCode"
                    prepend-icon="mdi-qrcode"
                    :readonly="!canEditTenant"
                    :label="$t('ERP Code')"
                    :rules="erpCodeRules"
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <search-address
                    :address="address"
                    :readonly="!canEditTenant"
                    @select="address = $event"
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="contactName"
                    prepend-icon="mdi-account-circle-outline"
                    :readonly="!canEditTenant"
                    :label="$t('Contact Name')"
                    :rules="contactNameRules"
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <inputTel
                    v-model="contactPhone"
                    prepend-icon="mdi-phone-outline"
                    :readonly="!canEditTenant"
                    :label="$t('Contact Phone')"
                    :rules="contactPhoneRules"
                    outlined
                    dense
                  />
                </div>
              </v-col>
              <v-col>
                <div class="my-2 subtitle-1">
                  <language-input
                    v-model="locale"
                    :readonly="!canEditTenant"
                    :label="$t('Language')"
                    prepend-icon="mdi-translate"
                    :rules="languageRules"
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-select
                    v-model="currency"
                    :menu-props="{offsetY:true}"
                    :items="currencies"
                    :readonly="!canEditTenant"
                    :label="$t('Currency')"
                    :rules="currencyRules"
                    prepend-icon="mdi-currency-usd-circle-outline"
                    item-text="displayName"
                    return-object
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-select
                    v-model="height"
                    :items="heights"
                    :readonly="!canEditTenant"
                    :rules="heightRules"
                    :menu-props="{offsetY:true}"
                    :label="$t('Height')"
                    prepend-icon=" mdi-ruler"
                    item-text="symbol"
                    return-object
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-select
                    v-model="temperature"
                    :menu-props="{offsetY:true}"
                    :items="temperatures"
                    :readonly="!canEditTenant"
                    :label="$t('Temperature')"
                    :rules="temperatureRules"
                    prepend-icon="mdi-thermometer"
                    item-text="symbol"
                    return-object
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-select
                    v-model="volume"
                    :menu-props="{offsetY:true}"
                    :items="volumes"
                    :readonly="!canEditTenant"
                    :label="$t('Volume')"
                    :rules="volumeRules"
                    prepend-icon="mdi-oil-temperature"
                    item-text="symbol"
                    return-object
                    outlined
                    dense
                  />
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-row v-if="tenant.address">
            <v-col>
              <entity-children-map
                :parent="tenant"
                :children="sites"
                @click:child="emit($event)"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-card-title class="pb-2 text-subtitle-1 font-weight-bold">
            {{ $t('MiSite Settings') }}
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="misiteUsername"
                    prepend-icon="mdi-account-circle-outline"
                    :readonly="!canEditTenant"
                    :label="$t('Username')"
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="password"
                    prepend-icon="mdi-form-textbox-password"
                    :readonly="!canEditTenant"
                    :label="$t('Password')"
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    outlined
                    dense
                    @click:append="showPassword = !showPassword"
                  />
                </div>
              </v-col>
              <v-col>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="id"
                    prepend-icon="mdi-identifier"
                    readonly
                    filled
                    :label="$t('Reference Id')"
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="alarmDuration"
                    prepend-icon="mdi-alarm"
                    :readonly="!canEditTenant"
                    :label="$t('Minimum Alarm Duration')"
                    outlined
                    dense
                    :rules="alarmDurationRules"
                  />
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
        <!-- Commented out due to security vulnerability
          <album-list
            :prefix="id"
            @set:cover-photo="setCoverPhoto"
          />
        -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card-text class="pa-0 ma-0">
            <UserDirectory
              v-if="canReadUsers"
              :can-edit-entity="canEditTenant"
              :entity-id="id"
              :tenant-id="tenantId"
            />
          </v-card-text>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
// import AlbumList from '@/components/common/photos/album/List.vue'
import UserDirectory from '@/components/users/UserDirectory.vue'
import SiteButtonDelete from '@/components/sites/SiteButtonDelete.vue'
import InputTel from '@/components/common/InputTel.vue'
import LanguageInput from '@/components/common/LanguageInput.vue'
import SearchAddress from '@/components/common/SearchAddress.vue'
import EntityChildrenMap from '@/components/common/EntityChildrenMap.vue'
import CoverPhoto from '@/components/common/photos/cover/Item.vue'
import CoverPhotoButton from '@/components/common/photos/cover/SetButton.vue'
export default {
  name: 'InfoCard',
  components: {
    UserDirectory,
    SiteButtonDelete,
    InputTel,
    LanguageInput,
    SearchAddress,
    EntityChildrenMap,
    // AlbumList,
    CoverPhoto,
    CoverPhotoButton
  },
  props: {
    id: {
      type: String,
      required: true
    },
    tenantId: {
      type: String,
      required: true
    },
    userName: {
      type: String,
      required: true
    },
    tenantGroupId: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      valid: false,
      title: '',
      name: '',
      description: '',
      erpCode: '',
      address: '',
      contactName: '',
      contactPhone: '',
      coverPhoto: '',
      height: {},
      volume: {},
      temperature: {},
      currency: {},
      locale: {},
      dialog: false,
      tenant: null,
      canEditTenant: false,
      canDeleteTenant: false,
      canReadUsers: false,
      password: '',
      showPassword: false,
      misiteUsername: '',
      alarmDuration: 0,
      alarmDurationRules: [
        v => (v >= 0 && v <= 15) || 'Duration must be between 0-15 minutes',
        v => !!v || 'Please fill in this field'
      ],
      descriptionRules: [],
      erpCodeRules: [],
      addressRules: [],
      contactPhoneRules: [],
      contactNameRules: [],
      currencyRules: [v => !!v || 'Please fill in this field'],
      temperatureRules: [v => !!v || 'Please fill in this field'],
      volumeRules: [v => !!v || 'Please fill in this field'],
      heightRules: [v => !!v || 'Please fill in this field'],
      languageRules: [v => !!v || 'Please fill in this field'],
      sites: []
    }
  },
  computed: {
    ...mapGetters(['currencies', 'volumes', 'heights', 'temperatures', 'locales']),
    images () {
      return [
        { src: 'https://images.globes.co.il/images/NewGlobes/big_image_800/2018/paz800x392.20181009T132348.jpg' },
        { src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRXuz3q6d3O4xfIL9FCGSt9oHmNuW82Hr9B0A&usqp=CAU' },
        { src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQWCOQi8CPuaBg7MJgUfbAqrn1h84N6wu4NPQ&usqp=CAU' }
      ]
    }
  },
  async created () {
    this.loading = true
    await this.getEntity()
    await this.setPermissions()
    this.sites = await this.$store.dispatch('getTenantSites', { tenantId: this.tenantId, flatResponse: true })
    this.loading = false
  },
  methods: {
    setCoverPhoto (event) {
      this.coverPhoto = event
      this.saveChanges(false)
    },
    emit (event) {
      this.$emit('change:selected', event)
    },
    async setPermissions () {
      try {
        this.canReadUsers = await this.enforce(this.id, 'users-read', this.userName, this.tenantId)
        this.canEditTenant = await this.enforce(this.id, 'entity-edit', this.userName, this.tenantId)
        if (this.tenantGroupId) {
          this.canDeleteTenant = await this.enforce('tenant-registry', 'tenant-delete', this.userName, this.tenantGroupId)
        }
      } catch (error) {
        this.$store.commit('showMessage', { message: error, color: 'error' })
      }
    },

    async enforce (entity, action, userName, tenantId) {
      this.loading = true
      try {
        const enforce = await this.$store.dispatch('enforce', {
          userName,
          tenantId,
          entity,
          action
        })
        return enforce
      } catch (error) {
        this.$store.commit('showMessage', { message: error, color: 'error' })
        return false
      } finally {
        this.loading = false
      }
    },
    async getEntity () {
      const data = await this.$store.dispatch('getEntity', {
        tenantId: this.tenantId,
        entityId: this.id
      })
      this.tenant = data
      if (!data) {
        return
      }
      this.title = data.name
      this.name = data.name
      this.description = data.description
      this.erpCode = data.erpCode
      this.address = data.address
      this.contactName = data.contactName
      this.contactPhone = data.contactPhone
      this.locale = this.locales.find(el => el.value === data.locale)
      this.currency = this.currencies.find(el => el.code === data.currencyCode)
      this.volume = this.volumes.find(el => el.code === data.volumeCode)
      this.height = this.heights.find(el => el.code === data.heightCode)
      this.temperature = this.temperatures.find(el => el.code === data.temperatureCode)
      this.password = data.password
      this.misiteUsername = data.username
      this.alarmDuration = data.alarmDuration || 0
      this.coverPhoto = data.coverPhoto
    },
    saveChanges (validate = true) {
      if (validate && !this.$refs.form.validate()) {
        return null
      }
      this.$store
        .dispatch('editEntity', {
          tenantId: this.tenantId,
          entityId: this.id,
          name: this.name,
          description: this.description,
          attributes: [
            { name: 'erpCode', value: this.erpCode },
            { name: 'address', value: this.address },
            { name: 'contactName', value: this.contactName },
            { name: 'contactPhone', value: this.contactPhone },
            { name: 'locale', value: this.locale ? this.locale.value : null },
            { name: 'currencyCode', value: this.currency ? this.currency.code : null },
            { name: 'volumeCode', value: this.volume ? this.volume.code : null },
            { name: 'heightCode', value: this.height ? this.height.code : null },
            { name: 'temperatureCode', value: this.temperature ? this.temperature.code : null },
            { name: 'username', value: this.misiteUsername },
            { name: 'alarmDuration', value: this.alarmDuration },
            { name: 'password', value: this.password },
            { name: 'coverPhoto', value: this.coverPhoto }
          ]
        })
        .then((data) => {
          this.$store.commit('showMessage', { message: `${this.title} updated successfully`, color: 'success' })
          this.getEntity()
          if (this.name !== this.title) {
            this.$store.dispatch('fetchEntities')
          }
        })
    }
  }
}
</script>
