<template>
  <div>
    <v-progress-linear
      v-if="loading"
      :active="loading"
      :indeterminate="loading"
    />
    <div
      id="map2"
    />
  </div>
</template>
<script>
import { credentials, mapName, transformRequest } from '@/app/location.js'
import Mapbox from 'mapbox-gl'

export default {
  props: {
    center: {
      type: Array,
      required: true
    },
    places: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      accessToken: 'pk.eyJ1IjoiZ2lsaWEiLCJhIjoiY2tvbDdxcXc5MGN2dTJwcGV4MDdieGNheSJ9._prw-hU90KiNqOKyWiLjRw',
      loading: false,
      map: null
    }
  },
  computed: {
    features () {
      return this.places.map(el => {
        return {
          type: 'Feature',
          properties: {
            id: el.id,
            description: el.description,
            payload: el.payload
          },
          geometry: {
            type: 'Point',
            coordinates: el.coordinates
          }
        }
      })
    },
    placesSourceData () {
      return {
        type: 'FeatureCollection',
        features: this.features
      }
    }
  },
  watch: {
    places (value) {
      if (!value.length || !this.map || !this.map.getSource('places')) {
        return
      }
      this.map
        .getSource('places')
        .setData(this.placesSourceData)
      var bounds = new Mapbox.LngLatBounds()
      this.features.forEach(el => {
        bounds.extend(el.geometry.coordinates)
      })
      this.map.fitBounds(bounds, {
        padding: 30
      })
    }
  },
  async created () {
    await this.createMap()
  },
  methods: {
    async createMap () {
      // load credentials and set them up to refresh
      this.loading = true
      await credentials.getPromise()
      // actually initialize the map
      const options = {
        accessToken: this.accessToken,
        container: 'map2',
        center: this.center,
        zoom: 8,
        style: mapName,
        transformRequest
      }
      this.map = new Mapbox.Map(options)
      this.map.on('load', () => {
        this.map.addSource('places',
          {
            type: 'geojson',
            data: this.placesSourceData
          }
        )
        this.map.addLayer({
          id: 'places',
          type: 'circle',
          source: 'places',
          paint: {
            'circle-color': '#4264fb',
            'circle-radius': 6,
            'circle-stroke-width': 2,
            'circle-stroke-color': '#ffffff'
          }
        })
        const popup = new Mapbox.Popup({
          closeButton: false,
          closeOnClick: false
        })
        this.map.on('mouseenter', 'places', (e) => {
          this.map.getCanvas().style.cursor = 'pointer'
          var coordinates = e.features[0].geometry.coordinates.slice()
          var description = e.features[0].properties.description
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
          }
          popup.setLngLat(coordinates).setHTML(description).addTo(this.map)
        })
        this.map.on('mouseleave', 'places', () => {
          this.map.getCanvas().style.cursor = ''
          popup.remove()
        })
        this.loading = false
      })
      this.map.on('click', 'places', (e) => {
        this.$emit('click:place', e.features[0].properties.payload)
      })
      this.map.addControl(new Mapbox.NavigationControl(), 'top-left')
    }
  }
}
</script>
<style scoped>
.mapboxgl-popup {
  max-width: 400px;
  font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }
#map2 {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100% !important;
}
</style>
