
<template>
  <v-card
    max-width="1200"
    flat
    class="pa-0 ma-0"
  >
    <v-card-title class="pb-2">
      {{ $t("User Directory") }}
      <v-spacer />
      <link-button
        :entity="entity"
        :tenant-id="tenantId"
        @change="getUsersPerEntity()"
      />
    </v-card-title>
    <v-divider />
    <v-card-subtitle>
      <v-text-field
        v-model="search"
        prepend-icon="mdi-feature-search-outline"
        :label="$t('Search')"
        single-line
        hide-details
        dense
        :loading="loading"
      />
    </v-card-subtitle>
    <v-list-item
      v-for="(item, i) in filteredItems"
      :key="i"
    >
      <v-list-item-avatar>
        <v-avatar
          :color="randomColor(i)"
          size="64"
          class="white--text"
        >
          {{ item.name.charAt(0).toUpperCase() }}
        </v-avatar>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>
          <strong>{{ item.name }}</strong>
          @{{ item.username }}
        </v-list-item-title>
        <v-chip-group>
          <v-chip
            v-for="(role, index) in item.roles"
            :key="index"
            :close="canDeleteRole(item, role, entity)"
            outlined
            pill
            @click:close="deleteRole(role, item)"
          >
            {{ role }}
          </v-chip>
        </v-chip-group>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import LinkButton from '@/components/users/UserButtonLink.vue'
import _ from 'lodash'
export default {
  components: {
    LinkButton
  },
  props: {
    entityId: {
      type: String,
      required: true
    },
    canEditEntity: {
      type: Boolean,
      required: true
    },
    tenantId: {
      type: String,
      required: true
    }

  },
  data: () => ({
    users: [],
    search: '',
    key: 0,
    entity: null,
    loading: true,
    colors: ['#cdb4db', '#ffafcc', '#a2d2ff', '#cdeac0']
  }),
  computed: {
    ...mapGetters(['currentTenantId']),
    filteredItems () {
      if (!this.users) return
      return _.orderBy(this.users.filter(item => {
        if (!this.search) {
          return this.users
        }
        return (item.username.toLowerCase().includes(this.search.toLowerCase()))
      }), 'username')
    }
  },
  created () {
    this.getUsersPerEntity()
    this.$store
      .dispatch('getEntity', {
        tenantId: this.tenantId,
        entityId: this.entityId
      })
      .then((data) => {
        this.entity = data
      })
  },
  methods: {
    canDeleteRole (item, role, entity) {
      if (!item.explicit_roles || !item.explicit_roles.includes(role)) {
        return false
      }
      return this.canEditEntity
    },
    randomColor (index) {
      return this.colors[index % this.colors.length]
    },
    async getUsersPerEntity () {
      this.users = []
      this.loading = true
      const users = await this.$store.dispatch('getUsersPerEntity', { entityId: this.entityId, tenantId: this.tenantId })
      this.users = users
      this.key += 1
      this.loading = false
    },
    async deleteRole (role, item) {
      const message = `Are you sure you want to delete ${role} from @${item.username}?`
      const res = await this.$confirm(message, { title: 'Delete Role from User', buttonTrueText: 'Delete', buttonFalseText: 'Cancel', color: 'primary', icon: '' })
      if (!res) return

      try {
        await this.$store.dispatch('deleteRoleFromUser', {
          tenantId: this.tenantId,
          entityId: this.entityId,
          role,
          username: item.username
        })

        if (this.currentTenantId === 'system') {
          await this.$store.dispatch('deleteRoleFromUser', {
            tenantId: 'system',
            entityId: this.entityId,
            role,
            username: item.username
          })
        }
        const message = `${role} role was deleted successfully`
        this.$store.commit('showMessage', { message, color: 'success' })
        this.getUsersPerEntity()
      } catch (error) {
        this.$store.commit('showMessage', { message: error, color: 'error' })
      }
    }
  }
}
</script>
