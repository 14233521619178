<template>
  <v-autocomplete
    v-model="selected"
    :items="addresses"
    :loading="isLoading"
    :search-input.sync="search"
    outlined
    dense
    no-filter
    item-text="label"
    item-value="coordinates"
    return-object
    :readonly="readonly"
    :label="$t('Address')"
  >
    <template
      v-slot:prepend
    >
      <map-icon
        :coordinates="selected ? selected.coordinates : [0, 0]"
      />
    </template>
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title v-if="!search">
          Search for an address
          <v-icon>
            mdi-map-search-outline
          </v-icon>
        </v-list-item-title>
        <v-list-item-title v-else-if="isLoading">
          Searching...
        </v-list-item-title>
        <v-list-item-title v-else>
          Address was not found!
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>
<script>
import MapIcon from '@/components/common/MapIcon.vue'
import { createClient } from '@/app/location.js'
export default {
  components: {
    MapIcon
  },
  props: {
    address: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      client: null,
      selected: null,
      addresses: [],
      isLoading: false,
      search: null,
      addressLabel: null
    }
  },
  watch: {
    addressLabel: {
      async handler (value) {
        if (!value) {
          return
        }
        await this.searchPlace(value)
        this.selected = this.addresses[0]
      }
    },
    address: {
      handler (value) {
        if (!value) {
          return
        }
        const splits = value.split(';')
        this.addressLabel = splits[0].split(':')[1]
      },
      immediate: true
    },
    selected (value) {
      if (!value || (this.addressLabel && value.label === this.addressLabel)) {
        return
      }
      this.$emit('select', `label:${value.label};coordinates:${value.coordinates}`)
    },
    async search (value) {
      if (this.addressLabel && value === this.addressLabel) {
        return
      }
      await this.searchPlace(value)
    }
  },
  methods: {
    async searchPlace (value) {
      this.addresses = []
      if (!value) {
        this.selected = ''
        return
      }
      this.isLoading = true
      this.addresses = await this.searchAddress(value)
      this.isLoading = false
    },
    getFormattedResults (results) {
      return results.map(el => {
        return {
          label: el.Place.Label,
          coordinates: el.Place.Geometry.Point
        }
      })
    },
    async searchAddress (place) {
      const params = {
        IndexName: 'orpak1-dev-place-index',
        Text: place
      }
      this.client = this.client ? this.client : await createClient()
      return new Promise((resolve, reject) => {
        this.client.searchPlaceIndexForText(params, (error, data) => {
          if (error) {
            reject(error)
          }
          if (data) {
            resolve(this.getFormattedResults(data.Results))
          }
        })
      })
    }
  }
}
</script>
