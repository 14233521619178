<template>
  <span>
    <slot name="button" />
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <component
        :is="component"
        :prefix="prefix"
        closeable
        @close="close"
        @set:cover-photo="$emit('set', $event), close()"
      />
    </v-dialog>
  </span>
</template>

<script>
// import AlbumList from '@/components/common/photos/album/List.vue'
export default {
  components: {
    // AlbumList
  },
  props: {
    prefix: {
      type: String,
      required: true
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialog: false,
      component: null
    }
  },
  watch: {
    open: {
      immediate: true,
      handler (value) {
        this.dialog = value
      }
    },
    dialog (value) {
      // if (!value) {
      this.component = null
      // return
      // }
      // this.component = AlbumList
    }
  },
  methods: {
    close () {
      this.dialog = false
      this.$emit('close')
    },
    toggle () {
      if (window.innerWidth > 500) {
        this.dialog = !this.dialog
      }
    }
  }
}
</script>
