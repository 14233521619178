<template>
  <v-container class="pa-0">
    <v-responsive
      min-height="320px"
      max-height="320px"
    >
      <v-hover v-slot="{ hover }">
        <v-card
          flat
          height="100%"
        >
          <img
            :src="`data:image/jpeg;base64,${item}`"
            loading="eager"
            style="height: 100%; width: 100%; object-fit: cover;"
          >
          <v-overlay
            :value="hover || overlay"
            absolute
          >
            <edit-button
              :prefix="prefix"
              @click="overlay = true"
              @close="close"
              @set="$emit('set', $event)"
            />
            <remove-button
              @click="overlay = true"
              @close="overlay = false"
              @remove="$emit('set', '')"
            />
          </v-overlay>
        </v-card>
      </v-hover>
    </v-responsive>
  </v-container>
</template>

<script>
import EditButton from '@/components/common/photos/cover/EditButton.vue'
import RemoveButton from '@/components/common/photos/cover/RemoveButton.vue'
import { viewPhoto } from '@/app/s3.js'
export default {
  components: {
    EditButton,
    RemoveButton
  },
  props: {
    prefix: {
      type: String,
      required: true
    },
    photoKey: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      item: null,
      overlay: false
    }
  },
  watch: {
    photoKey: {
      immediate: true,
      handler (newValue, oldValue) {
        if (!newValue || newValue === oldValue) {
          return null
        }
        this.view()
      }
    }
  },
  methods: {
    close () {
      setTimeout(() => {
        this.overlay = false
      }, 300)
    },
    view () {
      viewPhoto({ key: this.photoKey })
        .then((data) => {
          this.item = data
        })
        .catch((error) => {
          this.$store.commit('showMessage', { message: error, color: 'error' })
        })
    }
  }
}
</script>
