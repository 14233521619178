import { Signer } from '@aws-amplify/core'
import AWS from 'aws-sdk'
import Location from 'aws-sdk/clients/location'

const identityPoolId = 'eu-west-1:f7155c0a-f6ea-4269-a6f4-6a84b7b1e97a'
const REGION = identityPoolId.split(':')[0]
const credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: identityPoolId
}, {
  region: REGION
})

const mapName = 'Orpak1Map'

async function refreshCredentials () {
  await credentials.refreshPromise()
  setTimeout(refreshCredentials, credentials.expireTime - new Date())
}

refreshCredentials()

/**
 * Sign requests made by Mapbox GL JS using AWS SigV4.
 */
function transformRequest (url, resourceType) {
  if (resourceType === 'Style' && !url.includes('://')) {
    // resolve to an AWS URL
    url = `https://maps.geo.${REGION}.amazonaws.com/maps/v0/maps/${url}/style-descriptor`
  }
  if (url.includes('amazonaws.com')) {
    // only sign AWS requests (with the signature as part of the query string)
    return {
      url: Signer.signUrl(url, {
        access_key: credentials.accessKeyId,
        secret_key: credentials.secretAccessKey,
        session_token: credentials.sessionToken
      })
    }
  }
  // don't sign
  return { url }
}

const createClient = async () => {
  const client = new Location({
    credentials,
    region: REGION
  })
  return client
}

export {
  transformRequest,
  createClient,
  mapName,
  credentials
}
