<template>
  <v-btn
    fab
    small
    depressed
    @click="deleteEntity"
  >
    <v-icon color="error">
      mdi-delete-outline
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    entityId: {
      type: String,
      required: true
    },
    tenantId: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    isTenant: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  methods: {
    deleteEntity () {
      const message = `Are you sure you want to delete ${this.name}?`
      const deleteAction = (this.isTenant) ? 'deleteTenant' : 'deleteEntity'
      const deleteMessage = (this.isTenant) ? 'Delete Company' : 'Delete Group / Site'

      this.$confirm(message, { title: deleteMessage, buttonTrueText: 'Delete', buttonFalseText: 'Cancel', color: 'primary', icon: '' })
        .then(res => {
          if (res) {
            this.$store
              .dispatch(deleteAction, { entityId: this.entityId, tenantId: this.tenantId, id: this.tenantId })
              .then(() => {
                this.$emit('delete')
                const message = `${this.name} deleted successfully`
                this.$store.commit('showMessage', { message, color: 'success' })
                this.$store.dispatch('fetchEntities')
              })
              .catch(error => {
                this.$store.commit('showMessage', { message: error, color: 'error' })
              })
          }
        })
    }
  }
}
</script>
