<template>
  <select-cover-photo
    v-bind="$props"
    :open="open"
    @close="open = false"
    @set="$emit('set', $event)"
  >
    <template v-slot:button>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            small
            depressed
            v-on="on"
            @click="open = true"
          >
            <v-icon>
              mdi-file-image-outline
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('Set cover photo') }}</span>
      </v-tooltip>
    </template>
  </select-cover-photo>
</template>

<script>
import SelectCoverPhoto from '@/components/common/photos/cover/Select.vue'
export default {
  components: {
    SelectCoverPhoto
  },
  props: {
    prefix: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      open: false
    }
  }
}
</script>
